import Image001 from "../assets/csp_image001.png"

export function CSP_Features() {
	return (
		<>
			<div className="normal-text">

				<h1>
					<img width="62" height="62" src={Image001} />
					<span style={{ "color": "#005F00", "paddingLeft": "20px" }} >CRM & Sales Perspectives Features &amp; Revision History</span>
				</h1>

				<p>&nbsp;</p>

				<h2>September 23, 2024 (release 1.0.0)</h2>

				<ul>
					<li>Read items and change history from associated monday.com board</li>
					<li>Deals Progress Timeline Bars app settings: Assignment Column, Stage Column, Data Value Column, Creation Date Column, Close Date Column</li>
					<li>Deals Assignment & Stage Grid app settings: Assignment Column, Stage Column, Data Value Column, Grid Secondary Status Column</li>
					<li>Support settings changes</li>
					<li>Display items in Deals Progress Timeline Bars</li>
					<li>Display items in Deals Assignment & Stage Grid</li>
					<li>Display item information on hover</li>
					<li>Launch monday.com item card modal on click</li>
					<li>Support vertical scrolling with scrollbar or mouse wheel</li>
					<li>Support item card value changes</li>
					<li>Help/Instructions option</li>
				</ul>

				<p>&nbsp;</p>

			</div>
		</>
	);
}
