import "./App.css";
import { MainPage } from "./main-page";
import { IPC_Monday } from "./docs/ipc_monday";
import { IPC_PrivacyPolicy } from "./docs/ipc_privacy_policy";
import { IPC_TermsOfService } from "./docs/ipc_terms_of_service";
import { IPC_Features } from "./docs/ipc_features";
import { CSP_Monday } from "./docs/csp_monday";
import { CSP_PrivacyPolicy } from "./docs/csp_privacy_policy";
import { CSP_TermsOfService } from "./docs/csp_terms_of_service";
import { CSP_Features } from "./docs/csp_features";

export default function App() {

  const queryParams = new URLSearchParams(window.location.search);

  function renderPage() {
    if (queryParams.size === 0) {
      return <MainPage />;
    }
    const doc = queryParams.get("doc");
    switch (doc) {
      case "ipc_monday":
        return <IPC_Monday />;
      case "ipc_privacy_policy":
        return <IPC_PrivacyPolicy />;
      case "ipc_terms_of_service":
        return <IPC_TermsOfService />;
      case "ipc_features":
        return <IPC_Features />;
      case "ipc_monday_app_association": {
        const fileData = "{\"apps\":[{\"clientID\":\"8a8d5c3e5536acb0df64ff9f1285516e\"}]}";
        const blob = new Blob([fileData], { type: "text/plain" });
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.download = "monday-app-association.json";
        link.href = url;
        link.click();
        break;
      }
      case "csp_monday":
        return <CSP_Monday />;
      case "csp_privacy_policy":
        return <CSP_PrivacyPolicy />;
      case "csp_terms_of_service":
        return <CSP_TermsOfService />;
      case "csp_features":
        return <CSP_Features />;
      case "csp_monday_app_association": {
        const fileData = "{\"apps\":[{\"clientID\":\"9c6126816b43ff79277d82193df28707\"}]}";
        const blob = new Blob([fileData], { type: "text/plain" });
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.download = "monday-app-association.json";
        link.href = url;
        link.click();
        break;
      }
    }
  }

  return renderPage();
}
